'use client';

import React, { Suspense, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import useAuth from '@/hooks/Authentication/useAuth';
import { LoadingOverlay, MantineLoader } from '@whatsnxt/core-ui';
import { Anchor, Button, Divider, Group, Paper, PasswordInput, Stack, TextInput, Text, PaperProps, Container } from '@mantine/core';
import { upperFirst, useDisclosure, useToggle } from '@mantine/hooks';
import { useForm } from 'react-hook-form';
import { AuthAPI } from '../../../api';
import { notifications } from '@mantine/notifications';
import GoogleLoginContainer from '../../../components/Authentication/GoogleLogin';
const validationOptions = {
  otp: {
    required: 'OTP is required'
  },
  email: {
    required: 'Email is required',
    pattern: {
      value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
      message: 'Email is not valid.'
    }
  },
  password: {
    required: 'Password is required',
    minLength: {
      value: 8,
      message: 'Min 8 Characters required'
    }
  }
};
const Authentication = (props: PaperProps) => {
  const {
    login
  } = useAuth();
  const router = useRouter();
  const [otpSent, setOtpSent] = useState(false);
  const [visible, {
    open,
    close
  }] = useDisclosure(false);
  const [type, toggle] = useToggle(['login', 'register']);
  const isRegisterForm = type === 'register';
  const {
    register,
    handleSubmit,
    reset,
    formState: {
      errors
    }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      email: '',
      password: '',
      otp: ''
    },
    resetOptions: {
      keepDirtyValues: false,
      // user-interacted input will be retained
      keepErrors: true // input errors will be retained with value update
    }
  });
  useEffect(() => {
    if (!isRegisterForm) {
      setOtpSent(false);
    }
    reset();
  }, [isRegisterForm]);
  const onLoginHandler = async ({
    data
  }: {
    data: {
      email: string;
      password: string;
    };
  }) => {
    try {
      open();
      const loginData = {
        email: data.email,
        password: data.password,
        callbackUrl: `${window.location.origin}`,
        redirect: false
      };
      await login(loginData);
    } catch (error: any) {
      notifications.show({
        title: 'Authentication Error',
        message: error.message || 'Login failed!',
        color: 'red'
      });
    } finally {
      close();
    }
  };
  const onOtpSendHandler = async ({
    data
  }: {
    data: {
      email: string;
      password: string;
    };
  }) => {
    try {
      open();
      await AuthAPI.otp({
        email: data.email,
        name: '',
        otp: '',
        password: ''
      });
      setOtpSent(true);
      notifications.show({
        title: 'Success',
        message: 'OTP sent to your email.',
        color: 'green'
      });
    } catch (err: any) {
      notifications.show({
        title: 'Authentication Error',
        message: err.message || 'Registration failed! Try again',
        color: 'red'
      });
    } finally {
      close();
    }
  };
  const onRegisterHandler = async ({
    data
  }: {
    data: {
      email: string;
      password: string;
      otp: string;
    };
  }) => {
    try {
      open();
      const registrationData = {
        name: data.email.split('@')[0],
        username: data.email,
        email: data.email,
        password: data.password,
        creator: false,
        otp: data.otp
      };
      const auth = await AuthAPI.signup(registrationData);
      if (auth?.error) {
        notifications.show({
          title: 'Authentication Error',
          message: auth.error,
          color: 'red'
        });
      } else {
        notifications.show({
          title: 'Authentication Success',
          message: 'Registration success! Please login',
          color: 'green'
        });
        toggle('login');
        reset();
      }
    } catch (error: any) {
      notifications.show({
        title: 'Authentication Error',
        message: error?.message || 'Registration failed!',
        color: 'red'
      });
    } finally {
      close();
    }
  };
  const onSubmit = async (data: any) => {
    if (type === 'login') {
      onLoginHandler({
        data
      });
    }
    if (type === 'register') {
      !otpSent ? onOtpSendHandler({
        data
      }) : onRegisterHandler({
        data
      });
    }
  };
  const resolveSubmitButtonName = () => {
    if (type === 'register') {
      if (otpSent) return 'Register';else return 'Send OTP';
    } else {
      return 'Login';
    }
  };
  return <Suspense fallback={<MantineLoader />} data-sentry-element="Suspense" data-sentry-component="Authentication" data-sentry-source-file="Authentication.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="Authentication.tsx">
        <Paper radius="md" p="xl" withBorder {...props} data-sentry-element="Paper" data-sentry-source-file="Authentication.tsx">
          <Text size="xl" fw={500} data-sentry-element="Text" data-sentry-source-file="Authentication.tsx">
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </Text>
          <Group grow mb="md" mt="md" data-sentry-element="Group" data-sentry-source-file="Authentication.tsx">
            <GoogleLoginContainer data-sentry-element="GoogleLoginContainer" data-sentry-source-file="Authentication.tsx" />
          </Group>

          <Divider label="Or continue with email" labelPosition="center" my="lg" data-sentry-element="Divider" data-sentry-source-file="Authentication.tsx" />
          <LoadingOverlay visible={visible} data-sentry-element="LoadingOverlay" data-sentry-source-file="Authentication.tsx">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack data-sentry-element="Stack" data-sentry-source-file="Authentication.tsx">
                <TextInput required disabled={otpSent} label="Email" placeholder="enter email" {...register('email', validationOptions.email)} error={errors.email?.message && 'Invalid email'} radius="md" data-sentry-element="TextInput" data-sentry-source-file="Authentication.tsx" />

                {(type === 'register' && otpSent || type === 'login') && <PasswordInput required label="Password" placeholder="Your password" {...register('password', validationOptions.password)} error={errors.password?.message && 'Password should include at least 6 characters'} radius="md" />}
                {!isRegisterForm ? <Group justify="end">
                  <Anchor component="button" type="button" c="dimmed" onClick={() => {
                  router.push('/reset-password');
                }} size="xs">
                    Forgot password
                  </Anchor>
                </Group> : <></>}

                {type === 'register' && otpSent && <TextInput label="OTP" placeholder="Enter OTP" {...register('otp', validationOptions.otp)} radius="md" />}
              </Stack>
              <Group justify="space-between" mt="xl" data-sentry-element="Group" data-sentry-source-file="Authentication.tsx">
                <Anchor component="button" type="button" c="dimmed" onClick={() => toggle()} size="xs" data-sentry-element="Anchor" data-sentry-source-file="Authentication.tsx">
                  {type === 'register' ? 'Already have an account? Login' : "Don't have an account? Register"}
                </Anchor>
                <Button loading={visible} type="submit" radius="xl" data-sentry-element="Button" data-sentry-source-file="Authentication.tsx">
                  {upperFirst(resolveSubmitButtonName())}
                </Button>
              </Group>
            </form>
          </LoadingOverlay>
        </Paper>
      </Container>
    </Suspense>;
};
export default Authentication;