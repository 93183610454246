import { getCookie } from 'cookies-next';
import { AuthenticatedUserType } from './authTypes';

// Fetch user from cookies
export const fetchUserFromCookies = (): AuthenticatedUserType | null => {
  const storedUser = getCookie(process.env.NEXT_PUBLIC_COOKIES_USER || '');
  console.log('storedUser:', storedUser)
  return storedUser ? JSON.parse(storedUser as string)?.user : null;
};

// Check if access token exists
export const isAuthenticated = (): boolean => {
  const isAuthenticated = getCookie(process.env.NEXT_PUBLIC_COOKIES_ACCESS_TOKEN);
  return !!isAuthenticated;
};

// Initialize user authentication from cookies
export const initAuthFromCookies = (): AuthenticatedUserType | null => {
  const user = fetchUserFromCookies();
  return user ? user : null;
};
