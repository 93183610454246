import React, { useEffect, useContext } from 'react';
import { GoogleButton } from '@whatsnxt/core-ui/src/GoogleButton';
import { getCookie } from 'cookies-next';
import { redirect } from 'next/navigation';
import { AuthContext } from '../../context/Authentication/AuthContext'; // Import AuthContext

const GoogleLogin: React.FC = () => {
  const {
    setUser
  } = useContext(AuthContext); // Use setUser from AuthContext

  useEffect(() => {
    const fetchUser = async () => {
      const storedUser = getCookie(process.env.NEXT_PUBLIC_COOKIES_USER);
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser); // Update AuthContext with the fetched user
        redirect('/');
      }
    };
    fetchUser();
  }, [setUser]);
  const handleGoogleLogin = () => {
    // Redirect to the backend's Google OAuth endpoint
    const googleLoginUrl = `${process.env.NEXT_PUBLIC_GOOGLE_LOGIN_URL}/auth/google`;
    window.location.href = googleLoginUrl;
  };
  return <GoogleButton size='md' onClick={handleGoogleLogin} radius="xl" data-sentry-element="GoogleButton" data-sentry-component="GoogleLogin" data-sentry-source-file="GoogleLogin.tsx">
            Sign in with Google
        </GoogleButton>;
};
export default GoogleLogin;