import { AuthAPI } from '../../api';
import { notifications } from '@mantine/notifications';
import { LoginParamsType } from './authTypes';
import { fetchUserFromCookies } from './authUtils';

// Handle login logic
export const handleLogin = async (
  { email, password }: LoginParamsType,
  setUser: (user: any) => void,
  router: any
) => {
  try {
    const data = await AuthAPI.login({
      email,
      password,
      username: email,
    });

    if (data?.message) {
      const storedUser = fetchUserFromCookies();
      setUser(storedUser);
      notifications.show({
        title: 'Authentication Success',
        message: 'Login success. Redirecting...',
        color: 'green',
      });
      router.replace('/');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

// Handle logout logic
export const handleLogout = async (
  setUser: (user: null) => void,
  router: any
) => {
  try {
    const res = await AuthAPI.logout();
    setUser(null);
    if (res.message === 'Logout successful') {
      router.replace('/auth/authentication');
      notifications.show({
        title: 'Logout Successful',
        message: 'You have been logged out.',
        color: 'green',
      });
    }
  } catch (error) {
    notifications.show({
      title: 'Logout Error',
      message: 'There is some issue, try to refresh the site',
      color: 'red',
    });
    console.error('Logout error:', error);
  }
};
